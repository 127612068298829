@use "../../global" as global;

.p-press-release {

  &__container {
    padding: 3px;
    border: 2px solid global.$color-gray;
  }

  &__heading-wrapper {
    @include global.font-settings(16, 18, 1.8, 400);

    color: global.$color-white;
    text-align: center;
    background-color: global.$color-main;
  }

  &__list-wrapper {
    padding: 10px 0 10px 10px;
    overflow: hidden;
  }

  &__list {
    max-height: 200px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: global.$color-gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: global.$color-main;
    }
  }

  &__item {
    margin-bottom: 15px;

    @include global.font-settings(16, 16, 1.5, 400);
  }

  &__link {
    display: flex;
    transition: 0.2s;

    @include global.media(sp) {
      flex-direction: column;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__date-wrapper {
    margin-right: 2rem;

    @include global.media(sp) {
      margin-right: 0.5rem;
    }
  }

  &__new-wrapper {
    width: 2rem;
    margin-right: 2rem;

    @include global.media(sp) {
      margin-right: 0;
    }
  }

  &__new {
    @include global.font-settings(12, 12, 1.5, 400);

    width: 2rem;
    height: 14px;
    padding: 0 5px;
    color: global.$color-white;
    background-color: global.$color-red;
  }

  &__info-wrapper {
    display: flex;

    @include global.media(sp) {
      margin-bottom: 0.5rem;
    }
  }
}
