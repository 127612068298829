@use "../../global" as global;

.p-top-fv {

  &__container {
    position: relative;
    height: 466px;
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 10%);
  }

  &__title-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);

    @include global.media(sp) {
      width: 100%;
    }
  }

  &__title {
    color: global.$color-white;
    text-align: center;
    text-shadow: 1px 2px 3px #808080;

    @include global.font-settings(20, 32, 1.5, 600);
  }
}

@include global.media(sp) {

  .p-top-fv {

    &__container {
      z-index: -1;
      margin-top: global.$header-height-sp;
    }
  }
}
