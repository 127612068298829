@use "../../global" as global;

.p-company {

  &__contents {

  }

  &__row {
    display: flex;
    margin-bottom: 4rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include global.media(sp) {
      flex-direction: column;
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  &__col {
    flex: 1;
    padding: 0 20px;

    @include global.media(sp) {
      padding: 0;
      margin-bottom: 2rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__title-wrapper {
    margin-bottom: 2rem;
    border-bottom: 3px dotted  global.$color-main;
  }

  &__title {
    @include global.font-settings(20, 24, 1.5, 600);

    color: global.$color-main;
  }

  &__text {
    @include global.font-settings(16, 16, 1.5, 400);

    font-size: global.rem(16);
    line-height: 1.8;

    &--signature {
      margin-top: 1.5rem;
      text-align: end;

      @include global.font-settings(18, 18, 1.5, 600);
    }
  }

  &__item {
    @include global.font-settings(16, 16, 1.5, 400);

    margin-left: 2rem;
    list-style-type: disc;
  }

  &__dl {
    display: flex;
    margin-bottom: 1.5rem;
  }

  &__dt {
    width: 12rem;
    white-space: nowrap;
  }

  &__dd {
    width: 100%;
  }

  &__access-name-wrapper {
    margin-bottom: 1.5rem;
    border-bottom: 1px solid global.$color-main;
  }

  &__access-name {
    @include global.font-settings(16, 18, 1.5, 400);

    color: global.$color-main;
  }

  &__access-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  &__access-info {
    display: flex;

    @include global.media(sp) {
      flex-direction: column;
    }
  }

  &__access-item {
    width: 50%;

    @include global.media(sp) {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }

  &__item-wrapper {
    margin-bottom: 0.5rem;
  }

  &__map-wrapper {
    width: 50%;
    height: auto;

    @include global.media(sp) {
      width: 100%;
      height: auto;
      aspect-ratio: calc(19 / 6);
    }
  }

  &__map {
    width: 100%;
    height: 100%;

    @include global.media(sp) {
      width: 100%;
      height: auto;
      aspect-ratio: calc(5 / 3);
    }
  }
}
