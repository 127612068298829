@use "../../global" as global;

.p-recruit {

  &__contents {

  }

  &__row {
    display: flex;
    margin-bottom: 4rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include global.media(sp) {
      flex-direction: column;
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  &__col {
    flex: 1;
    padding: 0 20px;

    @include global.media(sp) {
      padding: 0;
      margin-bottom: 2rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__title-wrapper {
    margin-bottom: 2rem;
    border-bottom: 2px dotted  global.$color-main;
  }

  &__title {
    @include global.font-settings(20, 24, 1.5, 600);

    color: global.$color-main;
  }

  &__text {
    @include global.font-settings(16, 16, 1.5, 400);

    font-size: global.rem(16);
    line-height: 1.8;
  }

  &__heading-company {
    @include global.font-settings(17, 17, 1.5, 500);

    margin-top: 2rem;
    margin-bottom: 0.5rem;
    color: global.$color-main;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__item-heading {
    @include global.font-settings(16, 16, 1.5, 600);

    margin-top: 1.5rem;
  }

  &__s-button-wrapper {
    margin-top: 0.5rem;
  }

  &__item {
    @include global.font-settings(16, 16, 1.5, 400);

    margin-left: 2rem;
    list-style-type: disc;
  }

  &__dl {
    display: flex;
    margin-bottom: 1.5rem;
  }

  &__dt {
    width: 5rem;
  }

  &__access-name-wrapper {
    margin-bottom: 1.5rem;
    border-bottom: 1px solid global.$color-main;
  }

  &__access-name {
    @include global.font-settings(16, 18, 1.5, 400);

    color: global.$color-main;
  }

  &__access-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  &__access-info {
    display: flex;
  }

  &__item-wrapper {
    margin-bottom: 0.5rem;
  }

  &__form-wrapper {
    width: 100%;
    margin: 4rem 0 2rem;
  }

  &__sub-title-wrapper {
    margin-bottom: 1rem;
    border-bottom: 1px solid global.$color-main;
  }

  &__sub-title {
    @include global.font-settings(16, 17, 1.5, 500);
  }
}
