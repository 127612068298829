@use "../../global" as global;

.l-banner__inner .p-banner {
  @include global.media(sp) {
    // padding-top: 80px;
  }
}

.p-banner {

  &__img-wrapper {
    display: block;
    margin-bottom: 40px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 20%);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__img {
    transition: 0.2s;

    &:hover {
      opacity: 0.6;
    }
  }

  &__img-sp {
    display: none;
  }
}

@include global.media(sp) {

  .p-banner {

    &__img {
      display: none;
    }

    &__img-sp {
      display: block;
    }
  }
}
