@use "../../global" as global;

.c-section {

  &__heading-wrapper {
    margin-bottom: 1rem;
  }

  &__heading {
    @include global.font-settings(24, 30, 1.5, 600);

    color: global.$color-main;
    text-align: center;
  }

  &__lead-wrapper {
    margin-bottom: 3rem;
  }

  &__lead {
    @include global.font-settings(16, 16, 1.5, 400);
  }
}
