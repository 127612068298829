@use "../../global" as global;

.c-parallax-group {
  width: 100%;
  height: 470px;
  background-image: url("../images/sec-img1.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.c-parallax-company {
  width: 100%;
  height: 470px;
  background-image: url("../images/sec-img2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

_::-webkit-full-page-media,
_:future,
:root .c-parallax-group {
  background-attachment: scroll;
}

_::-webkit-full-page-media,
_:future,
:root .c-parallax-company {
  background-attachment: scroll;
}

@include global.media(sp) {

  _::-webkit-full-page-media,
  _:future,
  :root .c-parallax-group {
    height: 235px;
    background-attachment: scroll;
  }

  _::-webkit-full-page-media,
  _:future,
  :root .c-parallax-company {
    height: 235px;
    background-attachment: scroll;
  }
}
