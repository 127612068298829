@import "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap";
.c-button {
  font-size: clamp(1.125rem, (100vw - 767px) * 0.0078125 + 1.125rem, 1.25rem);
  font-weight: 600;
  line-height: 1.5;
  display: block;
  width: 340px;
  height: 50px;
  margin: 0 auto;
  line-height: 50px;
  color: #FFFFFF;
  text-align: center;
  background-color: #2F5D50;
  border-radius: 50px;
  transition: 0.2s;
}
@media only screen and (max-width: 767px) {
  .c-button {
    width: 100%;
  }
}
.c-button:hover {
  opacity: 0.8;
}
.c-button--small {
  height: 40px;
  font-size: 1rem;
  line-height: 40px;
}

.wp-element-button {
  font-size: clamp(1.125rem, (100vw - 767px) * 0.0078125 + 1.125rem, 1.25rem);
  font-weight: 600;
  line-height: 1;
  display: block;
  width: 340px;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 2.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #2F5D50;
  border-radius: 50px;
  transition: 0.2s;
}
@media only screen and (max-width: 767px) {
  .wp-element-button {
    width: 100%;
  }
}
.wp-element-button:hover {
  opacity: 0.8;
}
.wp-element-button--small {
  height: 40px;
  font-size: 1rem;
  line-height: 40px;
}

.c-parallax-group {
  width: 100%;
  height: 470px;
  background-image: url("../images/sec-img1.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.c-parallax-company {
  width: 100%;
  height: 470px;
  background-image: url("../images/sec-img2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

_::-webkit-full-page-media,
_:future,
:root .c-parallax-group {
  background-attachment: scroll;
}

_::-webkit-full-page-media,
_:future,
:root .c-parallax-company {
  background-attachment: scroll;
}

@media only screen and (max-width: 767px) {
  _::-webkit-full-page-media,
_:future,
:root .c-parallax-group {
    height: 235px;
    background-attachment: scroll;
  }
  _::-webkit-full-page-media,
_:future,
:root .c-parallax-company {
    height: 235px;
    background-attachment: scroll;
  }
}
.c-section__heading-wrapper {
  margin-bottom: 1rem;
}
.c-section__heading {
  font-size: clamp(1.5rem, (100vw - 767px) * 0.0234375 + 1.5rem, 1.875rem);
  font-weight: 600;
  line-height: 1.5;
  color: #2F5D50;
  text-align: center;
}
.c-section__lead-wrapper {
  margin-bottom: 3rem;
}
.c-section__lead {
  font-size: clamp(1rem, (100vw - 767px) * 0 + 1rem, 1rem);
  font-weight: 400;
  line-height: 1.5;
}

.p-top-fv__container {
  position: relative;
  height: 466px;
}
.p-top-fv__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-top-fv__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.p-top-fv__title-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 767px) {
  .p-top-fv__title-wrapper {
    width: 100%;
  }
}
.p-top-fv__title {
  color: #FFFFFF;
  text-align: center;
  text-shadow: 1px 2px 3px #808080;
  font-size: clamp(1.25rem, (100vw - 767px) * 0.046875 + 1.25rem, 2rem);
  font-weight: 600;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .p-top-fv__container {
    z-index: -1;
    margin-top: 74px;
  }
}
.p-banner__img-wrapper {
  display: block;
  margin-bottom: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.p-banner__img-wrapper:last-of-type {
  margin-bottom: 0;
}
.p-banner__img {
  transition: 0.2s;
}
.p-banner__img:hover {
  opacity: 0.6;
}
.p-banner__img-sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .p-banner__img {
    display: none;
  }
  .p-banner__img-sp {
    display: block;
  }
}
.p-company__row {
  display: flex;
  margin-bottom: 4rem;
}
.p-company__row:last-of-type {
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .p-company__row {
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
  }
}
.p-company__col {
  flex: 1;
  padding: 0 20px;
}
@media only screen and (max-width: 767px) {
  .p-company__col {
    padding: 0;
    margin-bottom: 2rem;
  }
  .p-company__col:last-of-type {
    margin-bottom: 0;
  }
}
.p-company__title-wrapper {
  margin-bottom: 2rem;
  border-bottom: 3px dotted #2F5D50;
}
.p-company__title {
  font-size: clamp(1.25rem, (100vw - 767px) * 0.015625 + 1.25rem, 1.5rem);
  font-weight: 600;
  line-height: 1.5;
  color: #2F5D50;
}
.p-company__text {
  font-size: clamp(1rem, (100vw - 767px) * 0 + 1rem, 1rem);
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
  line-height: 1.8;
}
.p-company__text--signature {
  margin-top: 1.5rem;
  text-align: end;
  font-size: clamp(1.125rem, (100vw - 767px) * 0 + 1.125rem, 1.125rem);
  font-weight: 600;
  line-height: 1.5;
}
.p-company__item {
  font-size: clamp(1rem, (100vw - 767px) * 0 + 1rem, 1rem);
  font-weight: 400;
  line-height: 1.5;
  margin-left: 2rem;
  list-style-type: disc;
}
.p-company__dl {
  display: flex;
  margin-bottom: 1.5rem;
}
.p-company__dt {
  width: 12rem;
  white-space: nowrap;
}
.p-company__dd {
  width: 100%;
}
.p-company__access-name-wrapper {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #2F5D50;
}
.p-company__access-name {
  font-size: clamp(1rem, (100vw - 767px) * 0.0078125 + 1rem, 1.125rem);
  font-weight: 400;
  line-height: 1.5;
  color: #2F5D50;
}
.p-company__access-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.p-company__access-info {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .p-company__access-info {
    flex-direction: column;
  }
}
.p-company__access-item {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .p-company__access-item {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
.p-company__item-wrapper {
  margin-bottom: 0.5rem;
}
.p-company__map-wrapper {
  width: 50%;
  height: auto;
}
@media only screen and (max-width: 767px) {
  .p-company__map-wrapper {
    width: 100%;
    height: auto;
    aspect-ratio: 3.1666666667;
  }
}
.p-company__map {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .p-company__map {
    width: 100%;
    height: auto;
    aspect-ratio: 1.6666666667;
  }
}

.p-group__content {
  margin-bottom: 6rem;
}
.p-group__content:last-of-type {
  margin-bottom: 0;
}
.p-group__heading-wrapper {
  margin-bottom: 1rem;
}
.p-group__heading {
  font-size: clamp(1.25rem, (100vw - 767px) * 0.015625 + 1.25rem, 1.5rem);
  font-weight: 600;
  line-height: 1.5;
  color: #2F5D50;
}
.p-group__text-wrapper {
  margin-bottom: 1rem;
}
.p-group__text {
  font-size: clamp(1rem, (100vw - 767px) * 0 + 1rem, 1rem);
  font-weight: 400;
  line-height: 2;
}

.p-press-release__container {
  padding: 3px;
  border: 2px solid #E2E2E2;
}
.p-press-release__heading-wrapper {
  font-size: clamp(1rem, (100vw - 767px) * 0.0078125 + 1rem, 1.125rem);
  font-weight: 400;
  line-height: 1.8;
  color: #FFFFFF;
  text-align: center;
  background-color: #2F5D50;
}
.p-press-release__list-wrapper {
  padding: 10px 0 10px 10px;
  overflow: hidden;
}
.p-press-release__list {
  max-height: 200px;
  overflow-y: scroll;
}
.p-press-release__list::-webkit-scrollbar {
  width: 5px;
}
.p-press-release__list::-webkit-scrollbar-track {
  background-color: #E2E2E2;
}
.p-press-release__list::-webkit-scrollbar-thumb {
  background-color: #2F5D50;
}
.p-press-release__item {
  margin-bottom: 15px;
  font-size: clamp(1rem, (100vw - 767px) * 0 + 1rem, 1rem);
  font-weight: 400;
  line-height: 1.5;
}
.p-press-release__link {
  display: flex;
  transition: 0.2s;
}
@media only screen and (max-width: 767px) {
  .p-press-release__link {
    flex-direction: column;
  }
}
.p-press-release__link:hover {
  opacity: 0.8;
}
.p-press-release__date-wrapper {
  margin-right: 2rem;
}
@media only screen and (max-width: 767px) {
  .p-press-release__date-wrapper {
    margin-right: 0.5rem;
  }
}
.p-press-release__new-wrapper {
  width: 2rem;
  margin-right: 2rem;
}
@media only screen and (max-width: 767px) {
  .p-press-release__new-wrapper {
    margin-right: 0;
  }
}
.p-press-release__new {
  font-size: clamp(0.75rem, (100vw - 767px) * 0 + 0.75rem, 0.75rem);
  font-weight: 400;
  line-height: 1.5;
  width: 2rem;
  height: 14px;
  padding: 0 5px;
  color: #FFFFFF;
  background-color: #EB5757;
}
.p-press-release__info-wrapper {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .p-press-release__info-wrapper {
    margin-bottom: 0.5rem;
  }
}

.p-recruit__row {
  display: flex;
  margin-bottom: 4rem;
}
.p-recruit__row:last-of-type {
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .p-recruit__row {
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
  }
}
.p-recruit__col {
  flex: 1;
  padding: 0 20px;
}
@media only screen and (max-width: 767px) {
  .p-recruit__col {
    padding: 0;
    margin-bottom: 2rem;
  }
  .p-recruit__col:last-of-type {
    margin-bottom: 0;
  }
}
.p-recruit__title-wrapper {
  margin-bottom: 2rem;
  border-bottom: 2px dotted #2F5D50;
}
.p-recruit__title {
  font-size: clamp(1.25rem, (100vw - 767px) * 0.015625 + 1.25rem, 1.5rem);
  font-weight: 600;
  line-height: 1.5;
  color: #2F5D50;
}
.p-recruit__text {
  font-size: clamp(1rem, (100vw - 767px) * 0 + 1rem, 1rem);
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
  line-height: 1.8;
}
.p-recruit__heading-company {
  font-size: clamp(1.0625rem, (100vw - 767px) * 0 + 1.0625rem, 1.0625rem);
  font-weight: 500;
  line-height: 1.5;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  color: #2F5D50;
}
.p-recruit__heading-company:first-of-type {
  margin-top: 0;
}
.p-recruit__item-heading {
  font-size: clamp(1rem, (100vw - 767px) * 0 + 1rem, 1rem);
  font-weight: 600;
  line-height: 1.5;
  margin-top: 1.5rem;
}
.p-recruit__s-button-wrapper {
  margin-top: 0.5rem;
}
.p-recruit__item {
  font-size: clamp(1rem, (100vw - 767px) * 0 + 1rem, 1rem);
  font-weight: 400;
  line-height: 1.5;
  margin-left: 2rem;
  list-style-type: disc;
}
.p-recruit__dl {
  display: flex;
  margin-bottom: 1.5rem;
}
.p-recruit__dt {
  width: 5rem;
}
.p-recruit__access-name-wrapper {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #2F5D50;
}
.p-recruit__access-name {
  font-size: clamp(1rem, (100vw - 767px) * 0.0078125 + 1rem, 1.125rem);
  font-weight: 400;
  line-height: 1.5;
  color: #2F5D50;
}
.p-recruit__access-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.p-recruit__access-info {
  display: flex;
}
.p-recruit__item-wrapper {
  margin-bottom: 0.5rem;
}
.p-recruit__form-wrapper {
  width: 100%;
  margin: 4rem 0 2rem;
}
.p-recruit__sub-title-wrapper {
  margin-bottom: 1rem;
  border-bottom: 1px solid #2F5D50;
}
.p-recruit__sub-title {
  font-size: clamp(1rem, (100vw - 767px) * 0.00390625 + 1rem, 1.0625rem);
  font-weight: 500;
  line-height: 1.5;
}