@use "../../global" as global;

.p-group {

  &__content {
    margin-bottom: 6rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__heading-wrapper {
    margin-bottom: 1rem;
  }

  &__heading {
    @include global.font-settings(20, 24, 1.5, 600);

    color: global.$color-main;
  }

  &__text-wrapper {
    margin-bottom: 1rem;
  }

  &__text {
    @include global.font-settings(16, 16, 2, 400);
  }
}
